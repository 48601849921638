import React from 'react'
import Section from '../section'
import { BasicButton } from '../button'
import { FileDownloadOutlined } from '@mui/icons-material'

export default function LetsTalk() {
    return (
        <Section id={'portfolio'} className={'projects-area'}>
            <div className="container">
                <div className="home-section border-dash">
                    <div className="section-title align-center">
                        <h2>Are You Ready to kickstart your project with a touch of magic?</h2>
                        <p>Reach out and let's make it happen ✨. I'm also available for full-time or Part-time opportunities to push the boundaries of design and deliver exceptional work.</p>
                        <div>
                            <BasicButton 
                                title='¡Hablemos!'
                                rightIcon={<FileDownloadOutlined />}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    )
}
