import React, { createContext, useState, ReactNode, useEffect } from 'react';

interface ModalManagerContextProps {
  openModal: (modalId: string, props?: any) => void;
  closeModal: (modalId: string, timer?: number) => void;
  modals: Map<string, { content: ReactNode; props?: any }>;
}

const ModalManagerContext = createContext<ModalManagerContextProps | undefined>(undefined);

export const ModalManagerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [modals, setModals] = useState<Map<string, { content: ReactNode; props?: any }>>(new Map());

  const openModal = (modalId: string, modalContent: ReactNode) => {
    setModals(prev => new Map(prev).set(modalId, { content: modalContent }));
  };

  const closeModal = (modalId: string, timer?: number) => {
    setTimeout(() => {
      setModals(prev => {
        const newModals = new Map(prev);
        newModals.delete(modalId);
        return newModals;
      });
    }, timer ? timer : 0); // Ajusta el tiempo según la duración de la animación
  };

  return (
    <ModalManagerContext.Provider value={{ openModal, closeModal, modals }}>
      {children}
      {Array.from(modals.entries()).map(([modalId, { content }]) => (
        <React.Fragment
          key={modalId}
        >
          {content}
        </React.Fragment>
      ))}
    </ModalManagerContext.Provider>
  );
};

export const useModalManager = () => {
  const context = React.useContext(ModalManagerContext);
  if (!context) {
    throw new Error('useModalManager must be used within a ModalManagerProvider');
  }
  return context;
};
