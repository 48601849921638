import { Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { BasicButton } from '../../button'
import navigation from '../../header/navigation.json';

export default function HeaderMenuModal({ close }) {
    return (
        <div className="sidebar-modal">
            <ul className='navigation'>
                {navigation.map((nav, i) => (
                    <li key={i}>
                        <BasicButton 
                            to={nav.to}
                            className='sidebar-modal'
                            component={Link}
                            title={nav.title}
                            onClick={close}
                        />
                    </li>
                ))}
            </ul>
        </div>
    )
}
