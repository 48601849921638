import { createContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

export const ColorsContext = createContext();

export const ColorsProvider = ({ children }) => {
    const params = useParams();
    const location = useLocation();

    const [ currentColor, setCurrentColor ] = useState('#EF3E30');
    const [ updating, setUpdating ] = useState(false);

    const changeColor = (color) => {
        if (currentColor === color) {
            return;
        }

        setUpdating(true);
        setTimeout(() => {
            setCurrentColor(color);
            setTimeout(() => {
                setUpdating(false);
            }, 600)
        }, 500)
    }

    useEffect(() => {
        if (params.language === 'asp-net') {
            changeColor('#ac99ea');
        } else if (params.language === 'laravel') {
            changeColor('#EF3E30');
        }
    }, [location.pathname])

    useEffect(() => {
        document.documentElement.style.setProperty('--branch-color', currentColor);
    }, [currentColor])

    return (
        <ColorsContext.Provider
            value={{
                currentColor,
                setCurrentColor,
                updating,
                setUpdating
            }}
        >
            {children}
        </ColorsContext.Provider>
    )
}