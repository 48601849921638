import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Button, CircularProgress, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import './Modal.scss'; // Asegúrate de que los estilos sean correctos para tu configuración.

interface ModalButtonsProps {
  buttons: {
    label: string;
    icon?: React.ReactNode;
    variant?: 'outlined' | 'high' | 'normal';
    onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
    loading?: boolean;
    disabled?: boolean;
    type?: 'warning' | 'normal';
  }[]
}

interface ModalProps {
  close: () => void;
  title: string;
  children: React.ReactNode;
  maxWidth?: number;
  noPadding?: boolean;
  variant?: 'fs' | 'fw' | 'fh';
  severity?: 'normal' | 'important';
  borderRadius?: number;
  modals?: React.ReactNode; // Añadido para soportar modales hijos
}

export const ModalButtons: React.FC<ModalButtonsProps> = ({
  buttons
}) => {
  return (
    <div className='modal-buttons'>
      {buttons.map((button, i) => (
        <Button key={i} disabled={button.disabled} onClick={button.onClick} className={`button modal-button size-big ${button.type ? `type-${button.type}` : ''} ${button.variant}`}>
          {button.icon && button.icon}
          <span>{button.label}</span>
          {button.loading && 
            <div className='loading'>
              <CircularProgress className='progress' />
            </div>
          }
        </Button>
      ))}
    </div>
  )
}

const Modal: React.FC<ModalProps> = ({
  close,
  title,
  children,
  maxWidth,
  noPadding,
  variant,
  severity,
  borderRadius,
  modals
}) => {
  const [ isVisible, setIsVisible ] = useState(false);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      close();
    }, variant === 'fs' ? 400 : 200)
  }

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 50)
  }, [])

  const handleClickBackdrop = () => {
    if (severity === 'important') return;

    handleClose();
  };

  const enhancedChildren = React.isValidElement(children)
    ? React.cloneElement(children as React.ReactElement, { close: handleClose })
    : children;

  return createPortal(
    <div className={`modal ${isVisible ? 'show' : ''} ${noPadding ? 'padding-false' : ''} ${variant ? `variant-${variant}` : ''}`}>
      <div className="backdrop" onClick={handleClickBackdrop}></div>
      <div className='modal_layout' style={{ borderRadius: borderRadius && borderRadius, maxWidth: maxWidth && maxWidth }}>
        {title && 
          <div className="title-layout">
            <div className='title'>{title}</div>
            {severity !== 'important' &&
              <IconButton className='IconButton' onClick={handleClose}>
                <Close />
              </IconButton>
            }
          </div>
        }
        <div className="content">
          {enhancedChildren}
          {modals && <div className='nested-modals'>{modals}</div>} {/* Renderiza los modales hijos */}
        </div>
      </div>
    </div>,
    document.getElementById('body') as HTMLElement
  );
}

export default Modal;
