import React from 'react'
import { LetsTalk } from '../../components/sections'
import { Section } from '../../components'
import { Input } from '../../components/input'
import { BasicButton } from '../../components/button'
import { Email, Place } from '@mui/icons-material'
import { useMediaQuery } from '@mui/material'

export default function Contact() {
    const match = useMediaQuery('(max-width: 570px)')

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('enviado')
    }

    return (
        <>
            <Section id="contact" className="contact-area">
                <div className="container">
                    <div className="home-section color-base">
                        <div className="section-title align-center">
                            <h2>Póngase en contacto conmigo</h2>
                        </div>
                        <div className="section-body margin-top-15">
                            <div className='data'>
                                <ul>
                                    <li>
                                        <Place />
                                        <h3>Ubicación:</h3>
                                        <p>Colombia</p>
                                    </li>
                                    <li>
                                        <Email />
                                        <h3>Envíenos un correo:</h3>
                                        <a href="mailto:contacto@andrescortes.dev ">contacto@andrescortes.dev </a>
                                    </li>
                                </ul>
                            </div>
                            <form onSubmit={handleSubmit} className="form flex flow-column gap-10">
                                <div className={`flex flow-${!match ? 'row' : 'column'} gap-10`}>
                                    <Input 
                                        label='Full Name'
                                        required
                                    />
                                    <Input 
                                        label='Email Addres'
                                    />
                                </div>
                                <div className='flex'>
                                    <Input 
                                        type='text-area'
                                        label='Your Message'
                                    />
                                </div>
                                <div>
                                    <BasicButton 
                                        title='Send Me Message'
                                        rightIcon={<Email />}
                                        type='submit'
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Section>
            <LetsTalk />
        </>
    )
}
