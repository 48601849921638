import React from 'react';
import './styles/index.scss';
import './styles/page.scss';

export default function App({ children }) {
  return (
    <>
      {children}
    </>
  )
}
