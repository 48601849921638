import React from 'react';
import './Footer.scss';

export default function Footer() {
  return (
    <div className='footer'>
        <footer>
            <p>Copyright @2024, AndresCortesDev Todos los derechos reservados.</p>
        </footer>
    </div>
  )
}
